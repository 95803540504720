import React from 'react';
import PropTypes from 'prop-types';
import {Box, Grid} from '@mui/material';
import {isEmpty} from 'lodash';
import compose from 'lodash/flowRight';
import PageLoader from '../../../../shared/components/pageLoader';
import SiteSelectionMenu from '../../../../shared/components/siteSelectionMenu/SiteSelectionMenu';
import {flattenSitesListForSearch} from '../../../../shared/util/sites';
import {useSiteSelector} from '../../../../shared/hooks';
import {getAppliancesByParams, getAppliance} from '../../../../api/appliances';
import {getBitrate} from '../../../../api/sites';
import {getCameras} from '../../../../api/sensors';
import withUrlState from '../../../../shared/components/withUrlState';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const SitePerformanceNewNavigation = ({history, site}) => {
  const [selectedSite, sitesList, , handleSiteSelect] = useSiteSelector(
    {},
    undefined,
    history,
    site,
    undefined,
  );
  const match = {
    url: `/home/sites/${selectedSite.id}/settings`,
  };
  return (
    <Box>
      <Grid container justifyContent="end" spacing={1} marginBottom={3}>
        <Grid item xs={12} md={4} lg={3}>
          <SiteSelectionMenu
            siteSearchList={
              !isEmpty(sitesList)
                ? flattenSitesListForSearch(sitesList, sitesList.name)
                : []
            }
            title={selectedSite.name || 'Select...'}
            rootCategory={sitesList}
            setCurrentSite={async (e) => {
              if (e.currentSite) {
                handleSiteSelect(e);
              }
            }}
            dropdownProps={{variant: 'outlined', size: 'small'}}
            autofocus={!selectedSite.id}
          />
        </Grid>
      </Grid>
      <PageLoader
        key="site-performance-new-nav"
        page={() => import('../sitePerformance')}
        resources={{
          site: () => Promise.resolve(selectedSite),
          appliances: async () => {
            const fetchedAppliances = (await getAppliancesByParams({
              siteId: selectedSite.id,
              active: true,
            })).results;
            return Promise.all(
              fetchedAppliances.map(async (a) => ({
                ...(await getAppliance(a.id)),
                cameraDiscovery: a.cameraDiscovery,
              })),
            );
          },
          match: () => Promise.resolve(match),
          averageBitrates: () => getBitrate(selectedSite.id),
          camerasAndSettings: async () => {
            const allCameras = await getCameras(selectedSite.id);
            return {allCameras};
          },
        }}
      />
    </Box>
  );
};
SitePerformanceNewNavigation.propTypes = {
  site: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default compose(
  withCurrentUser,
  withUrlState(),
)(SitePerformanceNewNavigation);
