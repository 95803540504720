import React, {useEffect, Fragment} from 'react';
import {Link} from 'react-router-dom';

import CloudQueue from '@mui/icons-material/CloudQueue';
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';

import {useApi} from '../../../../shared/hooks';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';
import {getCloudArchiveStatusCount} from '../../../../api/cloudArchive';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';

const CloudArchiveWidget = ({widgetConfig, currentUser}) => {
  const [cloudArchiveStatusCount, loading, isError, getStatusCount] = useApi(
    getCloudArchiveStatusCount,
  );

  useEffect(
    () => {
      getStatusCount();
    },
    [getStatusCount],
  );

  const statusCount = cloudArchiveStatusCount?.reduce(
    (acc, item) => {
      acc[item.status.toLowerCase()] = item.count;
      return acc;
    },
    {good: 0, warning: 0, error: 0},
  );

  return (
    <Grid item id={widgetConfig.widgetReference} {...widgetConfig.properties}>
      <Card
        sx={{
          p: 2,
          height: '220px',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        {loading ? (
          <CircularProgress size={50} sx={{m: 'auto'}} />
        ) : (
          <Fragment>
            {isError ? (
              <Alert
                id="error-cloud-archive"
                severity="error"
                sx={{width: '100%'}}
              >
                Error loading widget.
              </Alert>
            ) : (
              <Fragment>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    width: '50%',
                  }}
                >
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h6">Cloud Archive</Typography>
                  </Box>
                  <Typography
                    id="failing-cloud-archives"
                    sx={{fontSize: '60px', lineHeight: 1.2, mt: 2}}
                  >
                    {statusCount?.error}
                  </Typography>
                  <Button
                    sx={{p: 0}}
                    component={Link}
                    data-cy="ca-status-error"
                    to={{
                      pathname: isNewNavigationWebEnabled(currentUser)
                        ? '/home/system-health'
                        : '/hardware-health',
                      search: '?caStatus=error',
                      state: {fromDashboard: true},
                    }}
                    target={openInOneTab(currentUser)}
                  >
                    Sites with Failures
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    width: '50%',
                  }}
                >
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h6" sx={{ml: 2, visibility: 'hidden'}}>
                      spacing
                    </Typography>
                    <CloudQueue />
                  </Box>
                  <Typography
                    id="lagging-cloud-archives"
                    sx={{fontSize: '60px', lineHeight: 1.2, mt: 2}}
                  >
                    {statusCount?.warning}
                  </Typography>
                  <Button
                    sx={{p: 0}}
                    component={Link}
                    data-cy="ca-status-warning"
                    to={{
                      pathname: isNewNavigationWebEnabled(currentUser)
                        ? '/home/system-health'
                        : '/hardware-health',
                      search: '?caStatus=warning',
                      state: {fromDashboard: true},
                    }}
                    target={openInOneTab(currentUser)}
                  >
                    Sites with Warnings
                  </Button>
                </Box>
              </Fragment>
            )}
          </Fragment>
        )}
      </Card>
    </Grid>
  );
};

export default withCurrentUser(CloudArchiveWidget);
